import {
  login,
  getDashboardStats,
  getAllLevels,
  getAllTopics,
  getAllQuestionTypes,
  getAccessPriviliges,
  getAllUserRoles,
  getAllUsers,
  postupdateRoleAccess,
  postupdateLevel,
  postAddLevel,
  postupdateTopic,
  getLevelsAll,
  postAddTopic,
  postupdateVariant,
  getTopicsAll,
  GetTopicsByLevelId,
  GetVariantsByTopicId,
  postAddVariant,
  postUpdateUserRole,
  getUserRoles,
  postAddUserRoles,
  postDeleteUserRoles,
  postUpdateUsers,
  postAddUser,
  postDeleteUser,
  postAddQuestion,
  postFilterQuestions,
  getDeleteQuestion,
  postAddToCart,
  postRemoveToCart,
  postRemoveCartQuestions,
  getCartQuestions,
  getLevelsByUserId,
  postQuestionVerification,
  postUpdateQuestion,
  getCountCartQuestions,
} from "../../services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setLoginData } from "../../components/Login/loginSlice";
import { setUser } from "../../store/userSlice";
import { setToLocalStorage } from "../../utils/localStorage";
import { useNavigate } from "react-router";

export const useLogin = (onError, handleSuccess) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation(
    ({ userName, password }) => {
      return login(userName, password);
    },
    {
      onSuccess: (data) => {
        let { user, ...rest } = data.data;
        setToLocalStorage("authToken", data.token);
        setToLocalStorage("user", JSON.stringify(user));
        setToLocalStorage("websiteMenu", JSON.stringify(data.data.websiteMenu));
        setToLocalStorage("allowedUserAction", JSON.stringify(data.data.allowedUserAction));
        dispatch(setLoginData({ ...rest, token: data.token }));
        dispatch(setUser(user));

        let allowedRoutes = [];
        data.data.websiteMenu?.forEach((item) => {
        let path =  item.url;
        path && allowedRoutes.push(path);
        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => {
            let path = child.url;
            path && allowedRoutes.push("/"+path);
          });
        }
      });
      navigate(allowedRoutes[0]);
      
        // data.data.websiteMenu.forEach((das, index)=>{
        //     navigate(`${das.url}`);
        // })
      },
      onError,
    }
  );
};

export const useDashboardStats = () => {
  return useQuery(["fetchDashbaordStats"], () => getDashboardStats(), {
    select: (data) => {
      return data.data;
    },
    onError: () => {},
  });
};

export const useAllLevels = (page, rowsPerPage) => {
  return useQuery(["fetchAllLevels", { page, rowsPerPage }], () => getAllLevels(page, rowsPerPage), {
    select: (data) => {
      return { list: data.result, count: data.totalItems };
    },
    onError: () => {},
  });
};

export const useLevelsAll = () => {
  return useQuery(["fetchLevelsAll"], () => getLevelsAll(), {
    select: (data) => { 
      return { list: data.data };
    },
    onError: () => {},
  });
};

export const useAllTopics = (page, rowsPerPage) => {
  return useQuery(["fetchAllTopics", { page, rowsPerPage }], () => getAllTopics(page, rowsPerPage), {
    select: (data) => {
      return { list: data.result, count: data.totalItems };
    },
    onError: () => {},
  });
};

export const useAllQuestionTypes = (page, rowsPerPage) => {
  return useQuery(["fetchAllQuestionTypes", { page, rowsPerPage }], () => getAllQuestionTypes(page, rowsPerPage), {
    select: (data) => {
      return { list: data.result, count: data.totalItems };
    },
    onError: () => {},
  });
};

export const useAllAccessPriviiges = (userRole) => {
  return useQuery(["fetchAllAccessPriviliges", { userRole }], () => getAccessPriviliges(userRole), {
    select: (data) => {
      return data.data;
    },
    onError: () => {},
    enabled: Boolean(userRole),
  });
};

export const useAllUserRoles = () => {
  return useQuery(["fetchAllUserRoles"], () => getAllUserRoles(), {
    select: (data) => {
      return { list: data.data, count: data.data.length };
    },
    onError: () => {},
  });
};

export const useAllUsers = (page, rowsPerPage) => {
  return useQuery(["fetchAllUsers", { page, rowsPerPage }], () => getAllUsers(page, rowsPerPage), {
    select: (data) => {
      return { list: data.data.data, count: data.data.recordCount };
    },
    onError: () => {
      
    },
  });
};
// onError, handleSuccess
export const useupdateRoleAccess = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    ({ isChecked, menuRightId }) => {
      
      return postupdateRoleAccess(isChecked, menuRightId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllAccessPriviliges")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useupdateLevel = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    (updatedData) => {
      
      return postupdateLevel(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllLevels")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useAddLevel = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    (updatedData) => {
      
      return postAddLevel(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllLevels")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useUpdateTopic = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    (updatedData) => {
      
      return postupdateTopic(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllTopics")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useAddTopic = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  return useMutation(
    (updatedData) => {
      
      return postAddTopic(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllTopics")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useTopicsAll = () => {
  return useQuery(["fetchTopicsAll"], () => getTopicsAll(), {
    select: (data) => { 
      return { list: data.data };
    },
    onError: () => {},
  });
};

export const useUpdateVariant = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    (updatedData) => {
      
      return postupdateVariant(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllQuestionTypes")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};

export const useTopicsByLevelId = (LevelId) => {
  return useQuery(["fetchAllTopicsByLevelId", { LevelId }], () => GetTopicsByLevelId(LevelId), {
    enabled: !!LevelId,
    select: (data) => {
      return {list: data.data};
    },
    
    onError: () => {},
    
  });
};

export const useVariantsByTopicId = (topicId) => {
  return useQuery(["fetchAllVariantsByTopicId", { topicId }], () => GetVariantsByTopicId(topicId), {
    enabled: !!topicId,
    select: (data) => {
      return {list: data.data};
    },
    onError: () => {},
    
  });
};
// postAddVariant
export const useAddVariant = (successUpdate, handleError) => {
  const queryClient = useQueryClient()
  // debugger
  return useMutation(
    (updatedData) => {
      
      return postAddVariant(updatedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllQuestionTypes")
        successUpdate();
      },
      onError: (error)=>{
        
        handleError();
      }
    }
  );
};


export const useUserRoles = (page, rowsPerPage) => {
  return useQuery(["fetchAllUserRoles", { page, rowsPerPage }], () => getUserRoles(page, rowsPerPage), {
    select: (data) => { 
      // 
      return { list: data.data.data, count: data.data.recordCount };
    },
    onError: () => {},
  });
};
    export const useUpdateUserRole = (successUpdate, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (updatedData) => {
          
          return postUpdateUserRole(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUserRoles")
            successUpdate();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    }; 
 
    export const  useAddUserRoles = (successUpdate, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (updatedData) => {
          
          return postAddUserRoles(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUserRoles")
            successUpdate();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

    export const useDeleteUserRoles = (successDelete, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (UserRoleId) => {
          
          return postDeleteUserRoles(UserRoleId);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUserRoles")
            successDelete();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

    export const useUpdateUsers = (successUpdate, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (updatedData) => {
          
          return postUpdateUsers(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUsers")
            successUpdate();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    }; 
    export const useAddUser = (succesAdd, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (updatedData) => {
          
          return postAddUser(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUsers")
            succesAdd();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    }; 
    
    export const useDeleteUser = (successDelete, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (UserRoleId) => {
          
          return postDeleteUser(UserRoleId);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("fetchAllUsers")
            successDelete();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

    export const useAddQuestion = (succesAdd, handleError) => {
      // debugger
      return useMutation(
        (updatedData) => {
          
          return postAddQuestion(updatedData);
        },
        {
          onSuccess: () => {
            succesAdd();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    }; 
 
    export const useFetchQuestions = (page, rowsPerPage, selectedTopicByLevelId, selectedLevelId, selectedVariantsByTopicId, selectedUserId) => {
      return useQuery(["FetchQuestions", { page, rowsPerPage }], () => postFilterQuestions(page, rowsPerPage, selectedTopicByLevelId, selectedLevelId, selectedVariantsByTopicId, selectedUserId), {
        
        select: (data) => { 
          // 
          return { list: data.data.list.result, count:data.data.list.totalItems };
        },
        enabled : false,
        onError: () => {},
      });
    };

    export const useDeleteQuestion = (successDelete, handleError) => {
      const queryClient = useQueryClient()
      // debugger
      return useMutation(
        (QuestionId) => {
          
          return getDeleteQuestion(QuestionId);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchQuestions")
            successDelete();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

    export const useCartQuestions = ( selectedUserId) => {
      return useQuery(["FetchCartQuestions", { selectedUserId }], () => getCartQuestions( selectedUserId), {
        refetchOnMount: true,
        select: (data) => { 
          // 
          return { list: data.data, count: data.data.length };
        },
        onError: () => {},
      });
    };

    export const useAddToCart = (successAdd, handleError) => {
      const queryClient = useQueryClient()
      return useMutation(
        (updatedData) => {
          
          return postAddToCart(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchCountCartQuestions")
            successAdd();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

   

    
    export const useRemoveToCart = (successRemove, handleError) => {
      const queryClient = useQueryClient()
      return useMutation(
        (updatedData) => {
          
          return postRemoveToCart(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchCountCartQuestions")
            successRemove();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };


    export const useRemoveCartQuestions = (successRemove, handleError) => {
      const queryClient = useQueryClient()
      return useMutation(
        (updatedData) => {
          
          return postRemoveCartQuestions(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchCartQuestions")
            queryClient.invalidateQueries("FetchCountCartQuestions")
            successRemove();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };

    export const useLevelsByUserId = (selectedUserId) => {
      return useQuery(["FetchLevelsByUserId", { selectedUserId }], () => getLevelsByUserId(selectedUserId), {
        select: (data) => {
          return { list: data.data, count: data.data.length };
        },
        onError: () => {},
      });
    };
    export const useQuestionVerification = (successUpdate, handleError) => {
      const queryClient = useQueryClient()
      // 
      return useMutation(
        (updatedData) => {
          return postQuestionVerification(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchQuestions")
            successUpdate();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };
    export const useUpdateQuestion = (successUpdate, handleError) => {
      const queryClient = useQueryClient()
      // 
      return useMutation(
        (updatedData) => {
          return postUpdateQuestion(updatedData);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("FetchQuestions")
            successUpdate();
          },
          onError: (error)=>{
            
            handleError();
          }
        }
      );
    };


    export const useCountCartQuestions = (selectedUserId) => {
      return useQuery(["FetchCountCartQuestions", { selectedUserId }], () => getCountCartQuestions(selectedUserId), {
        select: (data) => {
          
          return { list: data.data };
        },
        onError: () => {},
      });
    };