// import {
//   BrowserRouter,
//   Routes,
//   Route,
//   Outlet,
//   Navigate,
// } from "react-router-dom";
// import { getFromLocalStorage } from "./utils/localStorage";

// import AppContainer from "./containers/AppContainer";
// import Counters from "./Pages/Counters";
// import Login from "./components/Login";
// import Levels from "./Pages/Levels";
// import Topics from "./Pages/Topics";
// import QuestionType from "./Pages/QuestionType";
// import AccessPrivileges from "./Pages/AccessPriviiges";
// import AddQuestion from "./Pages/AddQuestions";
// import EditQuestion from "./Pages/EditQuestion";
// import UserRole from "./Pages/UserRole";
// import Users from "./Pages/Users";
// import ViewQuestion from "./Pages/ViewQuestion";
// import ErrorPage from "./Pages/ErrorPage"; 
// import PrintPdfPage from "./Pages/PrintPdfPage";
// const Router = () => {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route element={<PrivateRoutes />}>
//           <Route exact path="/*" element={<AppContainer />}>
//             <Route index element={<Counters />} />
//             <Route path="setup/levels" element={<Levels />} />
//             <Route path="setup/topics" element={<Topics />} />
//             <Route path="setup/variants" element={<QuestionType />} />
//             <Route path="admin/roles" element={<UserRole />} />
//             <Route path="admin/users" element={<Users />} />
//             <Route path="setup/access_priviiges" element={<AccessPrivileges />}/>
//             <Route path="viewquestions" element={<ViewQuestion />} />
//             <Route path="PrintPDF" element={<PrintPdfPage />} />
//             <Route path="add-question" element={<AddQuestion />} /> 
//             <Route path="edit-question" element={<EditQuestion />} /> 
//           </Route>
//           <Route path="error-404" element={<ErrorPage />} />
//         </Route>

//       </Routes>
//     </BrowserRouter>
//   );
// };

// const PrivateRoutes = () => {
//   const authToken = getFromLocalStorage("authToken");
//   return authToken ? (
//     <Outlet />
//   ) : (
//     <Navigate
//       to={{
//         pathname: "/login",
//       }}
//     />
//   );
// };
// export default Router;
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { getFromLocalStorage } from "./utils/localStorage";

import AppContainer from "./containers/AppContainer";
import Login from "./components/Login";
import Test from "./Pages/Test";

const Counters = lazy(() => import("./Pages/Counters"));
const Levels = lazy(() => import("./Pages/Levels"));
const Topics = lazy(() => import("./Pages/Topics"));
const QuestionType = lazy(() => import("./Pages/QuestionType"));
const AccessPrivileges = lazy(() => import("./Pages/AccessPriviiges"));
const AddQuestion = lazy(() => import("./Pages/AddQuestions"));
const EditQuestion = lazy(() => import("./Pages/EditQuestion"));
const UserRole = lazy(() => import("./Pages/UserRole"));
const Users = lazy(() => import("./Pages/Users"));
const ViewQuestion = lazy(() => import("./Pages/ViewQuestion"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage"));
const PrintPdfPage = lazy(() => import("./Pages/PrintPdfPage"));
// const Test = lazy(() => import("./Pages/Test"));

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/*" element={<AppContainer />}>
            <Route index element={<Suspense fallback={null}><Counters /></Suspense>} />
            <Route path="setup/levels" element={<Suspense fallback={null}><Levels /></Suspense>} />
            <Route path="setup/topics" element={<Suspense fallback={null}><Topics /></Suspense>} />
            <Route path="setup/variants" element={<Suspense fallback={null}><QuestionType /></Suspense>} />
            <Route path="admin/roles" element={<Suspense fallback={null}><UserRole /></Suspense>} />
            <Route path="admin/users" element={<Suspense fallback={null}><Users /></Suspense>} />
            <Route path="setup/access_priviiges" element={<Suspense fallback={null}><AccessPrivileges /></Suspense>} />
            <Route path="viewquestions" element={<Suspense fallback={null}><ViewQuestion /></Suspense>} />
            <Route path="PrintPDF" element={<Suspense fallback={null}><PrintPdfPage /></Suspense>} />
            <Route path="add-question" element={<Suspense fallback={null}><AddQuestion /></Suspense>} />
            <Route path="edit-question" element={<Suspense fallback={null}><EditQuestion /></Suspense>} />
            <Route path="test" element={<Test />} />
          </Route>
          <Route path="error-404" element={<Suspense fallback={null}><ErrorPage /></Suspense>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
const PrivateRoutes = () => {
  const authToken = getFromLocalStorage("authToken");
  return authToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
};
export default Router;
