import React from "react";

const Button = ({
  type,
  primary,
  primarylight,
  dangerlight,
  danger,
  greylight,
  text,
  onClick,
  svg,
  medium,
  small,
  className,
  disabled
}) => {
  let classes;
  if (primary) {
    classes =
      "text-white bg-primary hover:bg-primaryHover rounded fill-white ";
  } else if (primarylight) {
    classes =
      "text-primary hover:text-white bg-primaryLight hover:bg-primary rounded hover:fill-white fill-primary ";
  } else if(dangerlight){
    classes =
      "text-danger hover:text-white bg-dangerLight hover:bg-danger rounded hover:fill-white fill-danger ";
    } else if (danger) {
      classes =
        "text-white bg-danger hover:bg-[#a8000c] rounded hover:fill-white fill-white ";
    } else if (greylight) {
      classes =
        "text-greytext hover:text-black bg-bgform hover:bg-bgformhover rounded hover:fill-white fill-danger ";
    }

  if(className){
    classes += className + " ";
  }

  if(svg){
    classes += " inline-flex items-center ";
  }

  if (small) {
    classes += " text-sm ";
  }
  else if (medium) {
    classes += " text-base ";
  }
  else{
    classes+= " text-lg "
  }

  return (
      <button
        type={type ? type : "button"}
        className={classes}
        onClick={onClick}
        disabled={disabled}
      >
        
        {svg && <span className="me-2">{svg}</span>}
        {text}
      </button>
  );
};

export default Button;
