const Input = ({ label, type, placeholder, value, onChange, errortext, onFocus, onBlur, onKeyUp,required }) => {
  
  return (
    <div>
      {label && <label className="block mb-1">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        className={`form-input text-lg focus:border-1 focus:outline-none bg-bgform focus:bg-[#e7e6e6] px-3 py-2 rounded w-full ${errortext ? 'bg-dangerLight' : ''} `}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        required={required}
      />
      {errortext &&
      <p className="text-danger" >{errortext}</p>
      }
    </div>
  );
};

export default Input;
