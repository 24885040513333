import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Stack,
  SvgIcon,
  Slider,
  Badge,
} from "@mui/material";

import Logo from "../assets/Images/Logo/Navbarlogo.svg";
import { ReactComponent as CartIcon } from "../assets/Images/Icons/cart-icon.svg";
import { ReactComponent as TextIcon } from "../assets/Images/Icons/text-icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/Images/Icons/LogOut.svg";
import { ReactComponent as MenuIcon } from "../assets/Images/SideBarIcons/menuicon.svg";
import AdminImage from "../assets/Images/avatars/Admin.png";
import StudentImage from "../assets/Images/avatars/Student.png";
import TeacherImage from "../assets/Images/avatars/Teacher.png";
import ProfileImage from "../assets/Images/avatars/profile.jpg";
import { logout } from "./Login/loginSlice";
import { userLoggedOut } from "../store/userSlice";
import { getFromLocalStorage } from "../utils/localStorage";
import { deleteFromLocalStorage } from "../utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useCountCartQuestions } from "../hooks/common";
const fontSize = [
  {
    value: 70,
    label: "70%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 100,
    label: "100%",
  },
  {
    value: 110,
    label: "110%",
  },
  {
    value: 120,
    label: "120%",
  },
  {
    value: 130,
    label: "130%",
  },
];

function Navbar({ onClick, Name, roleName, roleId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.userSlice);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [fontSizeMain, setFontSizeMain] = useState(100);
  var pathnamelist = pathname.split("/");
  var pathnamesplit = pathnamelist[1];
  var pathnamesplit1 = pathnamelist[2];
  const navigationConfig = JSON.parse(getFromLocalStorage("websiteMenu"));
  const [printAvailable, setPrintAvailable] = useState(true);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorElText, setAnchorElText] = useState(null);

  const open = Boolean(anchorElText);
  const handleClick = (event) => {
    setAnchorElText(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElText(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(userLoggedOut());
    deleteFromLocalStorage("authToken");
    deleteFromLocalStorage("user");
    deleteFromLocalStorage("websiteMenu");
    deleteFromLocalStorage("allowedUserAction");
    navigate("/login");
  };
  // document.querySelector('.root').style.fontSize='200%'
  const handleFontSize = (e) => {
    setFontSizeMain(e.target.value);
    document.documentElement.style.fontSize = `${e.target.value}%`;
  };
  const {data} = useCountCartQuestions(roleId);

  window.addEventListener('onbeforeunload', function() {
    dispatch(logout());
    dispatch(userLoggedOut());
    deleteFromLocalStorage("authToken");
    deleteFromLocalStorage("user");
    deleteFromLocalStorage("websiteMenu");
    deleteFromLocalStorage("allowedUserAction");
    navigate("/login");
});

  useEffect(() => {
    const clearLocalStorage = () => {
      dispatch(logout());
      dispatch(userLoggedOut());
      deleteFromLocalStorage("authToken");
      deleteFromLocalStorage("user");
      deleteFromLocalStorage("websiteMenu");
      deleteFromLocalStorage("allowedUserAction");
      navigate("/login");
    };
    const inactivityTimeout = 120 * 60 * 1000; // 2 hours in milliseconds
    let inactivityTimer;
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(clearLocalStorage, inactivityTimeout);
    };
    const activityEvents = ['mousedown', 'keydown', 'mousemove', 'touchstart'];
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer);
    });
    resetInactivityTimer();
    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer);
      });
    };
  }, []);



  useEffect(() => {
    let allowedRoutes = [];
    navigationConfig?.forEach((item) => {
      if(item.url === "/"){
        allowedRoutes.push("/");
      }
      let path = item.url ? item.url.split("/").pop() : null;
      path && allowedRoutes.push(path);
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          let childPath = child.url ? child.url.split("/").pop() : null;
          childPath && allowedRoutes.push(childPath);
        });
      }
    });
  if(allowedRoutes.includes("PrintPDF")){
    setPrintAvailable(true)
  }else{
    setPrintAvailable(false)
  }
}, []);

  return (
    <AppBar position="fixed" className="topNavBar" sx={{ backgroundColor: "white", boxShadow: "0 10px 20px 0 #f5f2fd" }} open={false}>
      <Container
        maxWidth="2xl"
        sx={{
          paddingRight: { xs: "10px", md: "16px" },
          paddingLeft: { xs: "10px" },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: { sm: "61px" },
          }}
        >
          <Button
            color="lightMain"
            variant="contained"
            disableElevation
            onClick={onClick}
            className="Mobile-Nav-Responsive-btn"
            sx={{
              marginRight: { xs: "20px", md: "32px" },
              display: "block",
              minWidth: "40px",
              minHeight: "40px",
              padding: "10px",
              textAlign: "center",
              backgroundColor: "#ffefe0",
              fill: "#bcbcbc",
            }}
          >
            <MenuIcon />
          </Button>
          <Typography
            // variant="h6"
            // noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
            }}
          >
            <img src={Logo} alt="" />
          </Typography>

          <Stack direction={'row'} flexGrow={1} 
            sx={{
              justifyContent: "flex-end",
              gap: { xs: "5px", md: "15px" },
            }}
          >
            {/* {printAvailable && */}
            <Link to="/PrintPDF">
              {/* <MailIcon color="action" /> */}
              <Button
                color="lightMain"
                variant="contained"
                disableElevation
                className={pathname === "/PrintPDF" ? "Cart-ButtonActive" : ""}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  minWidth: "40px",
                  minHeight: "40px",
                  padding: "11px",
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fill: "#bcbcbc",
                  "&:hover": {
                    backgroundColor: "#ffefe0",
                    fill: "#ff9e46",
                  },
                }}
              >
                <Badge badgeContent={data?.list} color="primary" className="PDF-Page-Counter">
                  <CartIcon />
                </Badge>
              </Button>
            </Link>
            {/* } */}
            <Button
              color="lightMain"
              variant="contained"
              onClick={handleClick}
              disableElevation
              sx={{
                my: 2,
                color: "white",
                display: "block",
                minWidth: "40px",
                minHeight: "40px",
                padding: "10px",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
                fill: "#bcbcbc",
                "&:hover": {
                  backgroundColor: "#ffefe0",
                  fill: "#ff9e46",
                },
              }}
            >
              <TextIcon />
            </Button>
            <Menu anchorEl={anchorElText} open={open} onClose={handleClose} padding={0} id="Impact">
              <Box
                sx={{
                  px: 4,
                  paddingBottom: 1,
                }}
              >
                <Stack sx={{ width: { xs: "230px", sm: "300px" } }}>
                  <Typography variant="h6" color="initial">
                    Font Size
                  </Typography>
                  <Slider defaultValue={100} min={70} max={130} step={10} marks={fontSize} value={fontSizeMain} onChange={(e) => handleFontSize(e)} />
                </Stack>
              </Box>
            </Menu>

            {/* User Dropdown */}
            <Tooltip title="Open settings" sx={{ boxShadow: "none" }}>
              <IconButton
                onClick={handleOpenUserMenu}
                disableRipple
                sx={{
                  ":hover": { backgroundColor: "transparent" },
                  padding: "0",
                }}
              >
                <Stack
                  sx={{
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <Typography
                    variant="p"
                    // noWrap
                    sx={{
                      mr: 2,
                      fontSize: "0.875rem",
                      textAlign: "end",
                    }}
                    className="capitalize "
                  >
                    {Name}
                  </Typography>
                  <Typography
                    variant="h6"
                    // noWrap
                    sx={{
                      mr: 2,
                      mt: 0,
                      fontSize: "1rem",
                      color: "#212121",
                      lineHeight: "1",
                    }}
                    className="capitalize "
                  >
                    {roleName}
                  </Typography>
                </Stack>
                {/* roleName === 'Admin' ? AdminImage :
                    roleName === 'Teacher' ? TeacherImage :
                    roleName === 'Student' ? StudentImage : null; */}
                {roleName === 'Admin' ? <Avatar variant="rounded" alt="Admin" src={AdminImage} /> :
                 roleName === 'Teacher' ? <Avatar variant="rounded" alt="Teacher" src={TeacherImage} /> :
                 roleName === 'Student' ? <Avatar variant="rounded" alt="Student" src={StudentImage} /> : 
                 <Avatar variant="rounded" alt="Student" src={ProfileImage} />
                   }
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={handleLogout}
                sx={{
                  color: "#bcbcbc",
                  "&:hover": {
                    color: "primary.main",
                    backgroundColor: "#FEFAF5",
                  },
                }}
              >
                <Typography textAlign="center" sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <SvgIcon component={LogoutIcon} fontSize="small" />
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
