import React, { createContext, useContext, useState, useEffect } from 'react';

const MyContext = createContext();

export function MyProvider({ children }) {
  const [globalVariable, setGlobalVariable] = useState(75);
  const [globalVariablemd, setGlobalVariablemd] = useState(82);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };
    // Add a resize event listener to update device width
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  return (
    <MyContext.Provider value={{ globalVariable, setGlobalVariable, deviceWidth, globalVariablemd, setGlobalVariablemd  }}>
      {children}
    </MyContext.Provider>
  );
}

export function useGlobalVariable() {
  return useContext(MyContext);
}
