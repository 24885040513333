import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    text: {
      primary: "#212121",
      secondary: "#797979",
      white: '#ffffff'
    },
    primary: {
      main: "#ff9e46",
      light: "#ffefe0",
      dark: "#fc7900",
      contrastText: "#ffffff",
    },
    lightMain: {
      main: "#f3f3f3",
    },
    pinkchips: {
      main: "#fff4eb",
      contrastText: "#797979",
    },
    grey: {
      main: "#f3f3f3",
      contrastText: "#797979",
    },
    questionsbg: {
      main: "#f9f9f9",
    },
  },
  typography:{
    span:{
      fontFamily: 'Outfit-Regular',
    },
    p:{
      fontFamily: 'Outfit-Regular',
    },
  }
});

export default theme;
