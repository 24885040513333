import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const userSlice = createSlice({
  name: "EditQuestion",
  initialState,
  reducers: {
    setUpdateQuestionMessage: (state, action) => action.payload,
    updateQuestionMessage: (state, action) => initialState,
  },
  extraReducers: {},
});
export const { setUpdateQuestionMessage, updateQuestionMessage } = userSlice.actions;

export default userSlice.reducer;
