import { fetchWrapper } from "./restApi";
const API_URL_GET = process.env.REACT_APP_API_URL;
export const login = (email, password) => {
  return fetchWrapper("POST", `${API_URL_GET}/Login`, {
    Email: email,
    Password: password,
  });
};

export const getDashboardStats = () => {
  return fetchWrapper("GET", `${API_URL_GET}/AdminDashboardCount`);
};

export const getAllLevels = (page, rowsPerPage) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetLevels?page=${page}&size=${rowsPerPage}`
  );
};

export const getLevelsAll = () => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetAllLevels`
  );
};

export const getAllTopics = (page, rowsPerPage) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetTopics?page=${page}&size=${rowsPerPage}`
  );
};

export const getAllQuestionTypes = (page, rowsPerPage) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetQuestionTypes?page=${page}&size=${rowsPerPage}`
  );
};

export const getAccessPriviliges = (userRole) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/PopulateAccessPrivileges?RoleId=${userRole}`
  );``
};

export const getLevels = (userId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetLevelsByUserId?UserId=${userId}`
  );
};


export const getQuestionTypes = (levelId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetTopicByLevelId?levelId=${levelId}`
  );
};

export const getAllUserRoles = () => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetAllUserRoles`
  );
};

export const getAllUsers = (page, rowsPerPage) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetUsers?PageIndex=${page}&PageSize=${rowsPerPage}`
  );
};

export const postupdateRoleAccess = (isAllowed, menuRightId) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateRoleAccess`, {
    isAllowed: isAllowed,
    menuRightId: menuRightId,
  });
};

export const postupdateLevel= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateLevel`,  updatedData
  );
};

export const postAddLevel= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddLevel`,  updatedData
  );
};

export const postupdateTopic= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateTopic`,  updatedData
  );
};

export const postAddTopic= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddTopic`,  updatedData
  );
};

export const getTopicsAll = () => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetAllTopics`
  );
};
export const GetTopicsByLevelId = (levelId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetTopicByLevelId?levelId=${levelId}`
  );
};
export const GetVariantsByTopicId = (topicId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetQuestionTypesByTopicId?TopicId=${topicId}`
  );
};

export const postupdateVariant= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateQuestionType`,  updatedData
  );
};
export const postAddVariant= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddQuestionType`,  updatedData
  );
};

export const postUpdateUserRole = (updatedData) =>{
  return fetchWrapper("POST", `${API_URL_GET}/UpdateUserRole`, updatedData);
}

export const getUserRoles = (page, rowsPerPage) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetUserRoles?PageIndex=${page}&PageSize=${rowsPerPage}`
  );
};

export const postAddUserRoles= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddUserRole`,  updatedData
  );
}; 
export const postDeleteUserRoles= (UserRoleId) => { 
  return fetchWrapper("GET", `${API_URL_GET}/DeleteUserRole?UserRoleId=${UserRoleId}`
  );
}; 
export const postUpdateUsers= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateUser`,  updatedData
  );
}; 
export const postAddUser= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddUser`,  updatedData
  );
};
export const postDeleteUser= (UserRoleId) => { 
  return fetchWrapper("GET", `${API_URL_GET}/DeleteUser?UserId=${UserRoleId}`
  );
}; 
export const postAddQuestion= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddQuestion`,  updatedData
  );
};

export const postFilterQuestions= (page, rowsPerPage, selectedTopicByLevelId, selectedLevelId, selectedVariantsByTopicId, selectedUserId) => {
 return fetchWrapper("GET", `${API_URL_GET}/FilterQuesitons?page=${page}&size=${rowsPerPage}&TopicId=${selectedTopicByLevelId}&LevelId=${selectedLevelId}&QuesitonTypeId=${selectedVariantsByTopicId}&UserId=${selectedUserId}`
  );
};
export const getDeleteQuestion= (QuestionId) => {
  return fetchWrapper("GET", `${API_URL_GET}/DeleteQuestion?QuestionId=${QuestionId}`
   );
 };
 export const postAddToCart= (AddToCartData) => {
  return fetchWrapper("POST", `${API_URL_GET}/AddToCart`,  AddToCartData
  );
};
export const postRemoveToCart= (AddToCartData) => {
  return fetchWrapper("POST", `${API_URL_GET}/RemoveToCart`,  AddToCartData
  );
};
export const postRemoveCartQuestions= (AddToCartData) => {
  return fetchWrapper("POST", `${API_URL_GET}/RemoveCartQuestions`,  AddToCartData
  );
};
export const getCartQuestions= (selectedUserId) => {
  return fetchWrapper("GET", `${API_URL_GET}/GetCartQuestions?UserId=${selectedUserId}`
   );
 };
 export const getLevelsByUserId= (selectedUserId) => {
  return fetchWrapper("GET", `${API_URL_GET}/GetLevelsByUserId?UserId=${selectedUserId}`
   );
 };
//  /api/GetLevelsByUserId
export const postQuestionVerification= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/QuestionVerification`,  updatedData
  );
};

export const postUpdateQuestion= (updatedData) => {
  return fetchWrapper("POST", `${API_URL_GET}/UpdateQuestion`,  updatedData
  );
};

export const getCountCartQuestions= (selectedUserId) => {
  return fetchWrapper("GET", `${API_URL_GET}/CountCartQuestions?UserId=${selectedUserId}`
   );
 };