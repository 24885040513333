import { Provider } from "react-redux";
import store from "./store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material";
import Theme from "./theme";
import Router from "./Routes";
import { MyProvider } from './MyContext';
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MyProvider>
          <ThemeProvider theme={Theme}>
            <Router />
          </ThemeProvider>
            </MyProvider>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        
      </QueryClientProvider>
    </>
  );
}

export default App;
