import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import {
  Collapse,
  Box,
  Drawer as MuiDrawer,
  List,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Stack,
} from "@mui/material";
import { ReactComponent as DotIcon } from "../assets/Images/Icons/List-icon-dot.svg";
import { ReactComponent as DashboardIcon } from "../assets/Images/SideBarIcons/DashboardIcon.svg";
import { ReactComponent as SetupIcon } from "../assets/Images/SideBarIcons/SetupIcon.svg";
import { ReactComponent as AdminIcon } from "../assets/Images/SideBarIcons/AdminIcon.svg";
import { ReactComponent as AddQuestionIcon } from "../assets/Images/SideBarIcons/AddQuestion.svg";
import { ReactComponent as ViewQuestionIcon } from "../assets/Images/SideBarIcons/ViewQuestionIcon.svg";
import { ReactComponent as PrintPDFIcon } from "../assets/Images/SideBarIcons/PrintPDFIcon.svg";
import BlurImageNavbar from "../assets/Images/bgImages/BlurImageNavbar.png";
import { isMobile } from 'react-device-detect';
import Navbar from "./Navbar";
import SideLogoOpen from "../assets/Images/Logo/SideNavLogoOpen.png";
import SideLogoClose from "../assets/Images/Logo/SideNavLogoClose.png";
import { Link, useLocation } from "react-router-dom";
import { useGlobalVariable } from "../MyContext";
import { getFromLocalStorage } from "../utils/localStorage";
import { useNavigate } from "react-router";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var pathnamelist = pathname.split("/");
  var pathnamesplit = pathnamelist[1];
  var pathnamesplit1 = pathnamelist[2];
  const [open, setOpen] = useState(false);
  const [toggleMouseEvent, setToggleMouseEvent] = useState(true);
  const { deviceWidth, setGlobalVariablemd, setGlobalVariable, globalVariablemd } = useGlobalVariable();
  const [sidebarShow, setSidebarShow] = useState(true);
  const [navOpenBtn, setNavOpenBtn] = useState(true);
  const user = JSON.parse(getFromLocalStorage("user"));
  const navigationConfig = JSON.parse(getFromLocalStorage("websiteMenu"));
  useEffect(() => {
    if (isMobile) {
      setGlobalVariablemd(16);
      setGlobalVariable(16);
      setSidebarShow(false);
    } else {
      setGlobalVariablemd(82);
      setSidebarShow(true);
    }
  }, [deviceWidth, setGlobalVariable]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMouseToggle = () => {
    setToggleMouseEvent(!toggleMouseEvent);
    if (globalVariablemd == 260) {
      setGlobalVariablemd(82);
    } else {
      setGlobalVariablemd(260);
    }
  };

  const [openSection, setOpenSection] = useState(null);
  const handleClick = (sectionId) => {
    setOpenSection((prevOpenSection) => (prevOpenSection === sectionId ? null : sectionId));
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarShow && isMobile && !event.target.closest(".MuiDrawer-root") && !event.target.closest(".Mobile-Nav-Responsive-btn")) {
        handleResponsiveNavClose();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [sidebarShow]);

  useEffect(() => {
    let allowedRoutes = [];
    navigationConfig?.forEach((item) => {
      if(item.url === "/"){
        allowedRoutes.push("/");
      }
      let path = item.url ? item.url.split("/").pop() : null;
      path && allowedRoutes.push(path);
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          let childPath = child.url ? child.url.split("/").pop() : null;
          childPath && allowedRoutes.push(childPath);
        });
      }
    });
  allowedRoutes.push("PrintPDF")
  if(allowedRoutes.includes(pathnamesplit) || allowedRoutes.includes(pathnamesplit1) || allowedRoutes.includes(pathname)){

  }else{
    navigate("/error-404")
  }
  // console.log(allowedRoutes)
}, []);

  const handleResponsiveNavClose = () => {
    setSidebarShow(false);
    setOpen(false);
    setToggleMouseEvent(true);
    setNavOpenBtn(true);
  };
  const handleResponsiveNavOpen = () => {
    setSidebarShow(true);
    setOpen(true);
    setToggleMouseEvent(false);
    setNavOpenBtn(false);
  };

  const renderIcon = (iconName) => {
    switch (iconName) {
      case "DashboardIcon":
        return <DashboardIcon />;
      case "SetupIcon":
        return <SetupIcon />;
      case "AdminIcon":
        return <AdminIcon />;
      case "AddQuestionIcon":
        return <AddQuestionIcon />;
      case "ViewQuestionIcon":
        return <ViewQuestionIcon />;
      case "PrintPDFIcon":
        return <PrintPDFIcon />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ position: "absolute", top: "0", bottom: "0", left: "0" }}>
        <CssBaseline />
        <Navbar onClick={handleResponsiveNavOpen} Name={user?.name} roleName={user?.roleName} roleId={user?.id} />
        {sidebarShow && (
          <Drawer
            className={` ${open ? "SideNavBarOpen " : "SideNavBarClose"} `}
            variant="permanent"
            open={open}
            sx={{ display: "block" }}
            onMouseEnter={(e) => {
              toggleMouseEvent ? handleDrawerOpen() : e.preventDefault();
            }}
            onMouseLeave={(e) => {
              toggleMouseEvent ? handleDrawerClose() : e.preventDefault();
            }}
          >
            <DrawerHeader
              sx={{
                borderColor: "transparent",
                marginBottom: "25px",
                // paddingX: '0px',
                position: "sticky",
                backgroundColor: 'white',
                ...(open ? { minHeight: { xs: "190px" } } : { minHeight: { xs: "66px" } }),
              }}
            >
              {open ? <img src={SideLogoOpen} alt="" className="pt-5 " /> : <img src={SideLogoClose} alt="" className="pt-1" />}

              {open && (
                <>
                  <h2 className="text-xl text-primary pt-2 capitalize">{user?.name}</h2>
                  <p className="text-sm text-[#797979] capitalize">{user?.roleName}</p>
                </>
              )}
              {navOpenBtn ? (
                <IconButton onClick={handleMouseToggle} className="NavbarTogglebtn">
                  {open ? (
                    <ChevronLeftIcon
                      sx={{
                        color: "white",
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                        boxShadow: "4px 4px 15px 0 #dec2aa",
                      }}
                    />
                  ) : (
                    <ChevronRightIcon
                      color="primary"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "50%",
                        boxShadow: "4px 4px 15px 0 #dec2aa",
                      }}
                    />
                  )}
                </IconButton>
              ) : (
                <IconButton onClick={handleResponsiveNavClose} className="NavbarTogglebtn">
                  <ChevronLeftIcon
                    sx={{
                      color: "white",
                      backgroundColor: "primary.main",
                      borderRadius: "50%",
                      boxShadow: "4px 4px 15px 0 #dec2aa",
                    }}
                  />
                </IconButton>
              )}
            </DrawerHeader>
            {open ?
              <Stack sx={{transform: "translateY(-45px)",marginBottom: '-40px'  , width: '100%' }} >
                <img src={BlurImageNavbar} alt="" />
              </Stack>
              :''
            }
            {/* Main Navbar */}
            <Stack sx={{ overflowY: "scroll", overflowX: "hidden" }}>
              {navigationConfig.map((menu) => (
                <List key={menu.menuId} sx={{ paddingY: 0 }}>
                  <Link to={menu.url}>
                    <ListItem disablePadding sx={{ display: "block" }} onClick={() => handleClick(menu.id)}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        className={pathname === menu.url || pathnamesplit === menu.title.toLowerCase() ? "active" : ""}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            fill: "#ffffffcc",
                            stroke: "#ffffffcc",
                          }}
                        >
                          {renderIcon(menu.icon)}
                        </ListItemIcon>
                        <ListItemText
                          primary={menu.title}
                          primaryTypographyProps={{
                            color: "#ffffffcc",
                          }}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                        {menu.children.length > 0 &&
                          (open ? openSection === menu.id ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} /> : "")}
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  {menu.children.length > 0 && open && (
                    <Collapse in={openSection === menu.id} timeout="auto" unmountOnExit>
                      <List>
                        {menu.children.map((subMenu) => (
                          <Link key={subMenu.id} to={"/"+subMenu.url}>
                            <ListItem disablePadding sx={{ display: "block" }} key={subMenu.id}>
                              <ListItemButton
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  // px: 2.5,
                                  paddingLeft: "60px",
                                }}
                                className={pathnamesplit1 === subMenu.url.split("/")[1] ? "SubMenuActive" : ""}
                              >
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? "10px" : "auto",
                                    justifyContent: "center",
                                    fill: "#ffffffcc",
                                  }}
                                >
                                  <DotIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={subMenu.title}
                                  primaryTypographyProps={{ color: "#ffffffcc" }}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </List>
              ))}
            </Stack>
          </Drawer>
        )}
      </Box>
    </>
  );
}

const navigationConfig = [
  {
    id: "dashboard",
    menuId: 1,
    title: "Dashboard",
    translate: "Dashboard",
    type: "item",
    icon: "DashboardIcon",
    url: "/",
    children: [],
  },
  {
    id: "Setup",
    menuId: 3,
    title: "Setup",
    translate: "Setup",
    type: "collapse",
    icon: "SetupIcon",
    url: null,
    children: [
      {
        id: "Levels",
        title: "Levels",
        type: "item",
        url: "setup/levels",
      },
      {
        id: "Topic",
        title: "Topic",
        type: "item",
        url: "setup/topics",
      },
      {
        id: "Variants",
        title: "Variants",
        type: "item",
        url: "setup/variants",
      },
      {
        id: "AccessPrivileges",
        title: "Access Privileges",
        type: "item",
        url: "setup/access_priviiges",
      },
    ],
  },
  {
    id: "Admin",
    menuId: 8,
    title: "Admin",
    translate: "Admin",
    type: "collapse",
    icon: "AdminIcon",
    url: null,
    children: [
      {
        id: "Roles",
        title: "Roles",
        type: "item",
        url: "admin/roles",
      },
      {
        id: "Users",
        title: "Users",
        type: "item",
        url: "admin/users",
      },
    ],
  },
  {
    id: "AddQuestions",
    menuId: 11,
    title: "Add Questions",
    translate: "Add Questions",
    type: "item",
    icon: "AddQuestionIcon",
    url: "/add-question",
    children: [],
  },
  {
    id: "ViewQuestions",
    menuId: 12,
    title: "View Questions",
    translate: "View Questions",
    type: "item",
    icon: "ViewQuestionIcon",
    url: "/viewquestions",
    children: [],
  },
  {
    id: "PrintPDF",
    menuId: 34,
    title: "Print PDF",
    translate: "Print PDF",
    type: "item",
    icon: "PrintPDFIcon",
    url: "/PrintPDF",
    children: [],
  },
];
